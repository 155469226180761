'use client';

import { api } from '@/trpc/react';
import { OrderList } from '@av/database';
import { OrderListItem, Product } from '@av/database';
import { NavigationItem } from '@av/ui/navigation-menu';
import { RefetchOptions } from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';

export interface OrderListType extends OrderList {
  items: (OrderListItem & { product: Product })[];
}
const ProductSearchContext = createContext<ProductSearchContextType>({
  categoryItems: [],
  orderLists: undefined,
  favoriteOrderList: undefined,
  refetchOrderLists: () => {},
});

interface ProductSearchContextType {
  categoryItems: NavigationItem[];
  orderLists?: OrderListType[] | undefined;
  favoriteOrderList?: OrderListType | undefined;
  // eslint-disable-next-line no-unused-vars
  refetchOrderLists?: (options?: RefetchOptions) => void;
}

export const useProductSearch = () => useContext<ProductSearchContextType>(ProductSearchContext);

const ProductSearchProvider = ({
  children,
  categoryItems,
}: ProductSearchContextType & { children: React.ReactNode }) => {
  const { data: orderLists, refetch: refetchOrderLists } = api.orderList.all.useQuery(undefined, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const favoriteOrderList = useMemo(
    () => orderLists?.find((orderList) => orderList.isFavorites),
    [orderLists]
  );
  const store = useMemo(
    () => ({ categoryItems, orderLists, favoriteOrderList, refetchOrderLists }),
    [categoryItems, orderLists, favoriteOrderList]
  );

  return <ProductSearchContext.Provider value={store}>{children}</ProductSearchContext.Provider>;
};

export default ProductSearchProvider;
